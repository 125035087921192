import React, { useState, useEffect} from 'react';
import TodoList  from './maincontent/TodoList';
import { Button, TextField, FormGroup } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; 

const LOCAL_STORAGE_KEY = 'TodoApp.todolist';

export default function AppMain(){
  const [todos, setTodos] = useState([]);
  const [value_NewTodo, setValue_NewTodo] = useState("");
  // const todoNameRef = useRef();
  
  useEffect(() => { 
    const storedTodos = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (storedTodos) setTodos(storedTodos);
   },[])
  useEffect(() => {localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(todos))}, [todos]);
  
  function toggleTodo(id) {
    const newTodos = [...todos];                               // make a copy of the list
    const todo = newTodos.find(todo => todo.id === id);        // find the item in the list with matching id
    todo.complete = !todo.complete;                            // toggle state of the checkbox for the matching item
    setTodos(newTodos);                                        // update the list state
  }
  
  const onChange_NewTodo = event => setValue_NewTodo(event.target.value);
  const resetTodoChange  = ()    => {setValue_NewTodo(""); /*value_NewTodo = "";*/}
  
  function onClick_AddTodo(event) { const name = value_NewTodo.trim();
    if (!(name === '')) setTodos(prevTodos => 
      [...prevTodos, 
        { id:uuidv4(), 
          name:name, 
          complete:false
        }
      ]);
    resetTodoChange();
    
  }
  
  function onClick_ClearCompletedTodo() {
    //                      todos.filter(todo => !todo.complete)
    const newClearedTodos = todos.filter(todo => !todo.complete);
    setTodos(newClearedTodos);
    console.log(` > Button event: onClick_ClearCompletedTodo
    ${newClearedTodos}`);
  }
  
/* | MAIN CONTENT IS A FLEXCOLUMN                                                        | 
 * | THE TODOLIST IS A FORMGROUP, BUT THE REST OF THE UI ARE STRUCTURED INSIDE FLEX ROWS | 
 * | INSIDE EACH ROW, COMPONENTS ARE WRAPPED IN A DIV, WHICH ACTS AS A FINAL SHELL FOR   | 
 * | CSS UTILITY CLASS STYLING SIMILAR TO BOOTSTRAP/TAILWIND ETC - THIS WORKS WELL WITH  | 
 * | ELEMENTS THAT CAN SET THEIR SIZE TO FILL THE SIZE OF THE PARENT DIV, ALLOWING MORE  | 
 * | DIRECT, CONVENTIONAL, AND UNIFIED CONTROL OVER LAYOUT LOGIC                         | 
 */                                                                                      
  
return(<main class="flexcc">
  <div class="overlay blur"></div>
  <div class="todolist">
    <FormGroup><TodoList todos={todos} toggleTodo={toggleTodo} /></FormGroup>
  </div>
  
  <div id="ROW_TEXTINPUT" class="flexrow mb4">
    
    <div id="WRAP_NEWTODO" class="ft w20 mb4 mt4"> <TextField
      id="NewTodo"
      label="Get something done"
      placeholder="slay the day"
      className={'textField'}
      margin="normal"
      variant="filled"
      fullWidth
      value={value_NewTodo}
      onChange={onChange_NewTodo}
    /></div>
  </div>
  
  <div id="ROW_BUTTONS" class="flexrow mb4">
    
    <div id="WRAP_ADDBUTTON" class="fb"><Button 
      variant="contained" 
      onClick={onClick_AddTodo}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mp0" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3A.5.5 0 018 4z"/></svg>
      Add
    </Button></div>
    
    <div id="WRAP_CLEARBUTTON" class="fb"><Button 
      variant="contained"
      onClick={onClick_ClearCompletedTodo}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mr2"><path d="M12.736 3.97a.733.733 0 011.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 01-1.065.02L3.217 8.384a.757.757 0 010-1.06.733.733 0 011.047 0l3.052 3.093 5.4-6.425a.247.247 0 01.02-.022z"/></svg>
      clear complete
    </Button></div>
    
  </div> 
  
  <div id="ROW_COUNTLEFTTODO" class="flexrow mb4">
    {todos.filter(todo => !todo.complete).length} tasks left!
  </div>
</main>);}
