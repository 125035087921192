import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
export default function Todo({todo, toggleTodo}) {
  function onChange_todo() {
    toggleTodo(todo.id);
  }
  return (
    <FormControlLabel 
      control={
        <Checkbox 
          color="primary" 
          checked={todo.complete} 
          onChange={onChange_todo}
        />} 
      label={todo.name} 
    />
  );
}
